import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import $ from 'jquery';

// Helpers
import analytics from '~/helpers/analytics';
import validate from '~/helpers/validate_form';
import { modalResetPassword } from '~/store/Sessions/Sessions';

// UI
import UI from './Register.ui';

class Register extends Component {
  static contextTypes = {
    router: PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };

    this.internalData = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      dpi: (Date.now()).toString(),
      preferredCurrency: `${process.env.NEXT_PUBLIC_CURRENCY.toUpperCase()}`,
      country: 'NI',
      birthDate: null,
      gender: null,
      passwordConfirm: '',
    };
    // Binds
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    validate();
  }

  handleChange(e, data) {
    const keys = data.split('.');
    const registerForm = this;
    registerForm[keys[0]][keys[1]] = e.target.value;
    this.setState(registerForm);
  }

  handleData() {
    const query = {
      firstName: this.internalData.firstName,
      lastName: this.internalData.lastName,
      email: this.internalData.email.toUpperCase(),
      password: this.internalData.password,
      dpi: this.internalData.dpi,
      preferredCurrency: this.internalData.preferredCurrency,
      country: this.internalData.country,
      birthDate: this.internalData.birthDate,
      gender: this.internalData.gender,
    };

    const queryPerson = {
      first_name: this.internalData.firstName,
      last_name: this.internalData.lastName,
      date_of_birth: '',
      email_1: this.internalData.email,
      gender: '',
      mobile_country_code: '',
      mobile_phone_1: '',
      mobile_phone_country: '',
      phone_1: '',
    };

    delete query.passwordConfirm;

    this.props.sessionStore.personRegister(query).then((res) => {
      if (res.success && res.action === 'personRegister') {
        let medalia_email;
        let medalia_name;
        const datos = JSON.parse(res.data.config.data);
        if (datos) {
          medalia_email = datos.email.toLowerCase();
          medalia_name = `${datos.firstName} ${datos.lastName}`;
        } else {
          medalia_email = null;
          medalia_name = null;
        }
        const medalia_user_login = true;
        const medalia_order_id = null;
        const medalia_order_state = null;
        const medalia_order_items = null;
        localStorage.setItem('medallia_email', medalia_email);
        localStorage.setItem('medallia_user_login', medalia_user_login);
        localStorage.setItem('medallia_name', medalia_name);
        localStorage.setItem('medallia_order_id', medalia_order_id);
        localStorage.setItem('medallia_order_state', medalia_order_state);
        localStorage.setItem('medallia_order_items', medalia_order_items);
        this.props.closeModals();
        this.props.accountStore.profilePerson(queryPerson);
        analytics.usuarioCreado(query.firstName, query.lastName);
        setTimeout(() => { window.location.reload(); }, 500);
      } else if (!res.success && res.action === 'personRegister') {
        if (res.error.status === 500) {
          this.setState({ error: 'Error al crear una cuenta, contacta a soporte.' });
        } else if (res.error.status === 400) {
          this.setState({ error: <span className="session" onClick={modalResetPassword} dangerouslySetInnerHTML={{ __html: res.error.data.errors[0].errorMessage }} /> });
        } else if (res && res.error && res.error.data && res.error.data.errors && res.error.data.errors[0].errorMessage) {
          if (res.error.data.errors[0].errorMessage.includes(':')) {
            const mensaje = res.error.data.errors[0].errorMessage.split(':');
            if (mensaje[1].trim() === 'ERROR') {
              this.setState({ error: 'Ha ocurrido un error.' });
            } else {
              this.setState({ error: mensaje[1].trim() });
            }
          } else {
            this.setState({ error: res.error.data.errors[0].errorMessage });
          }
        } else {
          this.setState({ error: 'Ha ocurrido un error.' });
        }
      }
    });
  }


  handleSubmit(e) {
    e.preventDefault();
    const isIE = (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1);
    if (isIE) this.handleData();
    else if (!isIE && $("form[name='register']").valid()) this.handleData();
  }

  render() {
    const customHeight = window.innerHeight - 10;
    const customMaxHeight = {
      maxHeight: customHeight,
    };
    return (
      <UI
        registerForm={this.internalData}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        modalType={this.props.modalType}
        error={this.state.error}
        customMaxHeight={customMaxHeight}
      />
    );
  }
}

Register.propTypes = {
  sessionStore: PropTypes.shape({
    personRegister: PropTypes.func,
  }).isRequired,
  accountStore: PropTypes.shape({
    profilePerson: PropTypes.func,
  }).isRequired,
  modalType: PropTypes.func.isRequired,
  closeModals: PropTypes.func.isRequired,
};

export default inject('sessionStore', 'accountStore')(observer(Register));
