'use client';
import he from 'he';
import { iconClub, iconEntretenimiento, iconHogar, iconMovil, iconPromociones, SVGiconIOT } from '~/helpers/svg';
import { Link } from '#/renderer/router';
import CartQuantity from '~/components/Headers/CartQuantity';
import SeccionesHeaderMobile from '~/components/Headers/SeccionesHeaderMobile';
import { usePageContext } from '#/renderer/usePageContext';
import {
  handelToggleSubItemSidebar,
  handleCloseMenuMobile,
  handleCloseSideBar,
  handleMenuLinks,
  handleMenuToggle,
  handleSubmenuMobile,
  handleSubmenuMobileTienda,
  handleToogleSideBar,
} from '~/components/Headers/handlers';
import Session from '~/components/Headers/Session';
import Search from './Search';
import { Fragment } from 'react';

const addChildren = (parent) => {
  const children = [];
  const getChildren = parent && parent.Hijos && parent.Hijos.length;
  const theChildren = parent.Hijos;
  if (getChildren) {
    for (let c = 0; c < theChildren.length; c += 1) {
      const child = theChildren[c];
      const link = he.decode(child.URL);
      const label = he.decode(child.Descripcion);
      const key = child.Unico;
      const subchildren = addChildren(child);
      if (subchildren.length) {
        children.push(
          <li key={`menu-${key}`}>
            <a href={link}>{label}</a>
            <ul>{subchildren}</ul>
          </li>,
        );
      } else {
        children.push(
          <li key={`menu-${key}`}>
            <a href={link}>{label}</a>
          </li>,
        );
      }
    }
  }
  return children;
};

export default function NavMenuLinks({ layoutData }) {
  const { pageProps: page } = usePageContext();
  const isDesktop = !page.isMobile;
  const optHeader = [];
  const sections = [];
  if (layoutData) {
    for (let i = 0; i < layoutData.MenuHeader.length; i += 1) {
      let item = layoutData.MenuHeader[i];
      const subItems = item.Hijos.map((item2, index2) => {
        const innerItems = item2.Hijos.map((item3, index) => (
          <div key={index} className='display-innerItem'>
            <a href={item3.URL} target={item3.Target}>{item3.Titulo}</a>
          </div>
        ));
        return (
          <Fragment key={index2}>
            <ul className={`subMenu-item item-${index2}`}>
              <li onClick={() => { handelToggleSubItemSidebar(index2) }}>
                <a href={item2.URL} target={item2.Target}>
                  {item2.Titulo}
                </a>
                <i className='ico-chevron-down'></i>
              </li>
              {innerItems}
            </ul>
          </Fragment>
        );
      })
      let element = (
        <Fragment key={i}>
          <li className={`menu-body-items-list-item item-${item.Titulo}`}>
            <a href={item.URL} target={item.Target}>{item.Titulo}</a>
            <i className='ico-chevron-right' onClick={() => { handleToogleSideBar(item.Titulo) }}></i>
          </li>
          <div className={`subMenu subMenu-${item.Titulo}`}>
            <div className='subMenu-tittle'>
              <a href={item.URL} target={item.Target}>{item.Titulo}</a>
              <div className='backMenu' onClick={handleCloseSideBar}>
                <i className='ico-arrow-left'></i>
                <span>Regresar</span>
              </div>
            </div>
            {subItems}
          </div>
        </Fragment>
      );
      optHeader.push(element)
    }
    for (let i = 0; i < layoutData.Secciones.length; i += 1) {
      let item = layoutData.Secciones[i];
      let element = (
        <li key={i}><a href={item.URL} target={item.Target}>{item.Titulo}</a></li>
      );
      sections.push(element)
    }

  }

  return (
    <div className="nav--menu" onMouseLeave={isDesktop ? handleMenuLinks : null}>
      <div className='nav--menu-tittle'>
        <p>Menu</p>
        <i className='ico-x' onClick={handleCloseMenuMobile}></i>
      </div>
      <div className='nav--menu-body'>
        <ul className='menu-body-head'>
          {sections}
        </ul>
        <div className='menu-body-search'>
          <Search />
        </div>
        <div className='menu-body-items'>
          <ul className='menu-body-items-list'>
            {optHeader}
          </ul>
        </div>
      </div>
      <aside className="nav--menu-slogan">{"slogan"}</aside>
      <Session />
    </div>
  );
}
