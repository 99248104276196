import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import { textChars, emailChars, passwordChars } from '~/helpers/char-restriction';

// Components
import Notification from '~/containers/Views/Notification/Notification.ui';
import { useConfigMC } from '~/store/Query/api';

const UI = ({ registerForm, handleChange, handleSubmit, modalType, error, customMaxHeight }) => {
  const { data } = useConfigMC();
  return (
    <section className="modal--form" style={customMaxHeight}>
     <header>
       <h3>Bienvenido a Tienda en Línea Claro</h3>
       <p dangerouslySetInnerHTML={{ __html: data?.body.sesion?.registro }} />
      </header>
      <form name="register" className="modal--register" onSubmit={handleSubmit}>
        <p>Nombre</p>
        <label htmlFor="first_name">
          <input
            id="first_name"
            name="first_name"
            type="text"
            maxLength={30}
            placeholder="Nombre(s)"
            onKeyPress={textChars}
            value={registerForm.first_name}
            onChange={(e) => { handleChange(e, 'internalData.firstName'); }}
          />
        </label>
        <p>Apellido</p>
        <label htmlFor="last_name">
          <input
            id="last_name"
            name="last_name"
            type="text"
            maxLength={30}
            placeholder="Apellido(s)"
            onKeyPress={textChars}
            value={registerForm.last_name}
            onChange={(e) => { handleChange(e, 'internalData.lastName'); }}
          />
        </label>
        <p>Correo electrónico:</p>
        <label htmlFor="registerEmail">
          <input
            id="registerEmail"
            name="registerEmail"
            type="text"
            placeholder="ejemplo@email.com"
            onKeyPress={emailChars}
              value={registerForm.email}
            onChange={(e) => { handleChange(e, 'internalData.email'); }}
          />
        </label>
        <p>Ingresá contraseña:</p>
        <label htmlFor="registerPass">
          <input
            id="registerPass"
            name="registerPass"
            type="password"
            placeholder="Ingresá tu contraseña"
            minLength={6}
            maxLength={12}
            onKeyPress={passwordChars}
            value={registerForm.password}
            onChange={(e) => { handleChange(e, 'internalData.password'); }}
          />
        </label>
        <p>Confirmá contraseña:</p>
        <label htmlFor="registerPassConfirm">
          <input
            id="registerPassConfirm"
            name="registerPassConfirm"
            type="password"
            placeholder="Confirmá tu contraseña"
            minLength={6}
            maxLength={12}
            onKeyPress={passwordChars}
            value={registerForm.passwordConfirm}
            onChange={(e) => { handleChange(e, 'internalData.passwordConfirm'); }}
          />
        </label>
        <div className="modal--register-passreq">
          <div>Personalizar tu contraseña</div>
          <span>Recuerda que debe tener:</span>
          <ul>
            <li>Entre 6 y 12 caracteres</li>
            <li>Al menos una letra y un número</li>
            <li>Sin espacios en blanco, ni caracteres especiales</li>
          </ul>
        </div>
        <div className="modal--register-comply">
          <label>
            <input
              type="checkbox"
              id="registerCompliance"
              name="registerCompliance"
            />
            <span>Al continuar estarás aceptando los </span>
          </label>
          <a href="/terms" target="_blank" rel="noopener noreferrer">Términos y Condiciones</a>
        </div>
        <button type="submit">
          <span>Crear Cuenta</span>
        </button>
      </form>
      <aside>
        <p>Ya tengo una cuenta</p> <a onClick={() => { modalType(null); }}>Iniciar Sesión</a>
        <span className="trigger-password hide" onClick={() => modalType('onPassReset')} />
      </aside>
      {(error) ? <Notification title={error} /> : <div />}
    </section>
  );
};

UI.propTypes = {
  registerForm: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalType: PropTypes.func.isRequired,
  error: PropTypes.string,
};

UI.defaultProps = {
  error: '',
};

export default UI;
