import blukai from '~/helpers/blukai';
import {
  showLoginModal,
  showRegisterModal,
} from '~/components/Headers/handlers';

const LoginBtn = () => (
  <div className="ui--login-btn ui-log-btn active ie-fix">
    <a
      className="ui--btn-login"
      onClick={() => {
        showLoginModal();
        blukai.function(window, 'portal_home', 'btn_inciosecion');
      }}
    >
      Iniciar Sesión
    </a>
    <a
      className="ui--btn-register"
      onClick={() => {
        showRegisterModal();
        blukai.function(window, 'portal_home', 'btn_registrate');
      }}
    >
      Registrate
    </a>
  </div>
);

export default LoginBtn;
